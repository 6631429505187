$(document).on('turbolinks:load', function () {
  if ($('.wizard-connect-software').length > 0) {
    let $otherSwLink = $('.wizard-connect-software__other-button');
    let $useDentalinkLink = $('.wizard-connect-software__dentalink-button');
    let $backLink = $('.wizard-connect-software--showed-section').find('.wizard-connect-software__back-link');
    let $form = $('.wizard-connect-software__connection-form');
    let $submitButton = $('.wizard-connect-software__form-submit');
    let $connecting = $('.wizard-connect-software__status');
    let $title = $('.wizard-title');
    let $gertyCodeTextarea = $('.gerty-code-textarea');
    let $validationMessage = $('.validation-message');
    let isValidCode = false;

    const validateTextarea = () => {
      let gertyCode = $gertyCodeTextarea.val().trim();

      if (gertyCode.length !== 81) {
        showValidationMessage("El código debe tener exactamente 81 caracteres.", false);
        $submitButton.addClass("disabled")
        isValidCode = false;
      } else {
        validateGertyCode(gertyCode).then((data) => {
          isValid = data.valid;
          if (isValid) {
            showValidationMessage("El código es válido.", true);
            $submitButton.removeClass("disabled")
          } else {
            showValidationMessage("El código es inválido.", false);
            $submitButton.addClass("disabled")
          }
        }).catch((error) => {
          if (error.responseJSON && error.responseJSON.max_retries) {
            showValidationMessage("Intentos máximos alcanzados, por favor contacta a soporte.", false);
            $gertyCodeTextarea.prop('disabled', true);
            $submitButton.addClass("disabled")
          } else {
            showValidationMessage("Error, el código no es válido.", false);
            $submitButton.addClass("disabled")
          }
          isValidCode = false;
        });
      }
    };

    $otherSwLink.on('ajax:success', (ev) => {
      toggleSection($otherSwLink);
    });

    $backLink.on('click', (ev) => {
      ev.preventDefault();
      setTitle('title');
      toggleSection($backLink);
    });

    $useDentalinkLink.on('click', (ev) => {
      ev.preventDefault();
      toggleSection($useDentalinkLink);
    });

    $gertyCodeTextarea.on('change', validateTextarea);

    $form.on('submit', (ev) => {
      ev.preventDefault();
      if (isValidCode) {
        $.ajax({
          type: "POST",
          url: $form.attr('action'),
          data: $form.serialize(),
          success: function () {
            setTitle('title-connecting');
            toggleSection($form);
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.error("Error al procesar la solicitud: " + jqXHR.responseText)
            console.error("Error de conexión:", textStatus, errorThrown);
            setTitle('title-error');
          }
        });
      } else {
        showValidationMessage("Por favor, introduce un código válido antes de seguir.", false);
      }
    });

    let showValidationMessage = (message, isValid) => {
      $validationMessage.text(message).show();
      if (isValid) {
        $validationMessage.css('color', 'green');
        $gertyCodeTextarea.css('border-color', 'green');
      } else {
        $validationMessage.css('color', 'red');
        $gertyCodeTextarea.css('border-color', 'red');
      }
    };

    let validateGertyCode = async (code) => {
      return await $.ajax({
        type: "POST",
        url: "/wizard/connect/validate_gerty_code",
        data: { gerty_code: code },
        success: function (response) {
          return [response.valid, response.message, false];
        },
        error: function (response) {
          if (response.responseJSON && response.responseJSON.max_retries) {
            return [false, response.message, true];
          } else {
            return [false, response.message, false];
          }
        }
      });
    };

    let setTitle = (dataTitle) => {
      $title.html($title.data(dataTitle));
    };

    let toggleSection = ($element) => {
      let classToHide = $element.data('classToHide');
      let classToShow = $element.data('classToShow');

      let $elementToHide = $("." + classToHide + "");
      let $elementToShow = $("." + classToShow + "");

      $elementToHide.removeClass(classToHide).addClass(classToShow);
      $elementToShow.removeClass(classToShow).addClass(classToHide);
    };

    let checkStatus = () => {
      if ($connecting.length > 0 && $connecting.parent('div').hasClass('wizard-connect-software--showed-section')) {
        $.ajax({
          type: "GET",
          url: $connecting.data('url'),
          success: function (data) {
            let connection_status = data['connection_status'];
            if (connection_status === "success") {
              showGertyCode(data);
              successConnection();
            }
            else if (connection_status == "failed" || connection_status == null) {
              if (connection_status == "failed") {
                console.error("Error, endpoint probablemente inválido");
              } else {
                console.error("Error debido a estado de conexión: variable no encontrada");
              }
              failedConnection();
            }
            else {
              showConnectionMessage(data);
            }
          },
          error: function (data) {
            console.error("Error al conectar con Gerty: ", data);
            failedConnection();
          }
        });
      }
    };

    let showGertyCode = (data) => {
      if ($('.wizard-connect-software__gerty-code').html() == "") {
        $('.wizard-connect-software__gerty-code').html(data['gerty_code']);
      }
    }

    let showConnectionMessage = (data) => {
      $('.wizard-connect-software__message .wizard-subtitle').html(data['message']);
    }

    let successConnection = () => {
      clearInterval(intervalId);
      setTitle('title-success');
      toggleSection($('.wizard-connect-software__success'));
    }

    let failedConnection = () => {
      clearInterval(intervalId);
      setTitle('title-error');
      toggleSection($connecting);
    }

    let intervalId = window.setInterval(function () {
      checkStatus();
    }, 1000);

    // Llamar a la validación cuando se carga la página para validar el código si ya hay algo en el textarea
    validateTextarea();
  }
});
