import $ from "jquery";

$( document ).on('turbolinks:load', function(){
  // use of select2 on forms
  $('.select2').select2({
    width: "100%",
    height: "500px"
  });

  $('#billingModal').on('shown.bs.modal', function() {
    initializeModalSelect2('#billingModal');
  });

  $('#bank_account_modal').on('shown.bs.modal', function() {
    initializeModalSelect2('#bank_account_modal');
  });

  // por compatibilidad mantengo la siguiente clase, pero prontó se quitará
  $('.js-example-basic-single').select2({
    width: "100%",
    height: "500px"
  });

  const initializeModalSelect2 = (modalId) => {
    $(`${modalId} .select2-modal, ${modalId} .select2`).select2({
      width: "100%",
      height: "500px",
      dropdownParent: $(modalId)
    });
  };
});